import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/global.css'
import VueCookies from "vue-cookies";
Vue.config.productionTip = false
Vue.prototype.$baseUrl = process.env.VUE_APP_BASE_API
Vue.use(ElementUI,{size: 'medium'})
Vue.use(VueCookies);
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
