<template>
  <div>
    <el-button icon="el-icon-circle-plus-outline" type="success" @click="adddushu">发布读书心得</el-button>
    <div style="text-align: center">个人读书心得</div>

    <el-row style="margin-bottom: 20px" >
      <el-col :span="12" v-for="(item, index) in dataList" :key="index" style="padding-top: 10px">
        <el-card :body-style="{ padding: '0px',height: '200px' }" style="cursor: pointer;position: relative">
          <div style="padding: 14px;">
            <div class="bottom clearfix" style="text-align: center;">
              <div style="font-size: 16px;margin-bottom:5px;font-weight: bold">{{item.title}}</div>
              <div style="margin-bottom:5px;"><span>发布日期：</span>{{item.inputtime}}</div>
              <div style="margin-bottom:5px;"><span>图书名称：</span>{{item.bookName}}</div>
              <div style="margin-bottom:5px;"><span>图书分类：</span><el-tag>{{item.categoryName}}</el-tag></div>
              <div  style="margin-left: 2px;text-align: left;position: absolute;bottom: 0">
                <i class="el-icon-view"><span style="margin-left: 3px">{{item.eye}}</span></i>
                <img src="../assets/点赞.png" style="margin-left: 5px;width: 20px;height: 18px"><span>{{item.love}}</span>
                <button @click="getBlogInfo(item)">查看详情</button>
                <button @click="edit(item)">编辑</button>
                <button @click="deleteById(item.id)">删除</button>
              </div>

            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-dialog @close="closeDialog" :fullscreen="fullscreen" title="发布读书心得" :visible.sync="dialogFormVisible">
      <el-form style="width: 100%" :model="form">
        <el-form-item label="标题" :label-width="formLabelWidth" prop="title">
          <el-input v-model="form.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="图书" label-width="80px" prop="bookId">
          <div class="block">
            <el-cascader
                style="width: 100%"
                size="medium"
                v-model="value"
                :options="options"
                @change="handleChange"></el-cascader>
          </div>
        </el-form-item>
        <el-form-item label="内容" :label-width="formLabelWidth" prop="content">
           <div id="editor"></div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
import E from 'wangeditor'
export default {
  name: "MyBlog",
  data(){
    return{
      fullscreen: true,
      form: {
        content: '',
        url: '',
        eye: 0,
        love: 0
      },
      editor: null,
      dialogFormVisible: false,
      formLabelWidth: "80px",
      value: [],
      options: [],
      dataList: [],
      user: {
        id: 0
      }
    }
  },
  created() {
    this.loadTree()
    this.loadData()
  },
  methods: {
    deleteById(id){
      this.$confirm('此操作将永久删除该文章, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        request.delete(`/blog/deleteById/${id}`).then(res=>{
          if(res.code=='200'){
            this.$message.success("删除成功")
            this.loadData()
          }
        }).catch(err=>{
          console.log(err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    edit(item){
      console.log(item)
      request.get(`/blog/${item.id}`).then(res=>{
        if(res.code=='200'){
          this.form = res.data
          this.value[0] = res.data.categoryId
          this.value[1] = res.data.bookId
          this.$nextTick(()=>{
            this.editor = new E(`#editor`)
            //配置图片上传地址
            let url = this.$baseUrl
            url = url + "/user/editor/upload"
            this.editor.config.uploadImgServer = url
            this.editor.config.uploadFileName = 'file'
            this.editor.create()
            this.editor.txt.html(res.data.content)
          })
          this.dialogFormVisible = true
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    getBlogInfo(item){
      // console.log(item.id)
      let id = item.id
      this.$router.push({
        path: '/article',
        query: {
          id
        }
      })
    },
    closeDialog(){
      //销毁编辑器

      this.editor.destroy()
      this.editor = null
    },
    //提交数据到后台
    submitForm(){
      //获取编辑器的内容,获取用户id
      let userId = JSON.parse(localStorage.getItem("user")).id
      let content = this.editor.txt.html()
      this.form.content = content
      this.form.userId = userId
      this.form.eye = 0
      this.form.love = 0
      console.log(this.form)

      request.post("/blog/save",this.form).then(res=>{
        if(res.code=='200'){
          this.$message.success("保存文章成功")
        }else {
          this.$message.error("保存文章失败")
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    adddushu(){
      this.dialogFormVisible = true
      this.form = {}

      //等div加载
      this.$nextTick(()=>{
        this.editor = new E(`#editor`)

        //配置图片上传地址
        let url = this.$baseUrl
        url = url + "/user/editor/upload"
        this.editor.config.uploadImgServer = url
        this.editor.config.uploadFileName = 'file'

        this.editor.create() //创建
      })

    },
    handleChange(value) {

      console.log(value[1]);
      this.form.bookId = value[1]
    },
    loadData(){
      this.user.id = JSON.parse(localStorage.getItem("user")).id
      request.post("/blog/myblog",this.user).then(res=>{
        if(res.code=='200'){
          console.log(res.data)
          this.dataList = res.data
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    loadTree(){
      request.get("/books/tree").then(res=>{
        if(res.code=='200'){
          this.options = res.data
        }
      }).catch(err=>{
        console.log("err=>",err)
      })
    }
  }
}
</script>

<style>
.image {
  width: 80%;
  display: block;
  text-align: center;
}

</style>
