<template>
  <div id="app">
    <!--    头部区域-->
    <div style="display: flex;background-color: azure;line-height: 150px;height: 150px">
      <div style="height: 150px; width: 340px; margin-bottom: 2px">
        <img src="@/assets/logo.png" alt="显示失败" style="width: 40px;
      position: relative;top: 10px;left: 10px">
        <span style="margin-left: 10px;font-size: 16px;font-weight: bold">基于用户画像的图书馆借阅推荐管理系统</span>
      </div>
      <div style="flex: 1;text-align: right;padding-right: 30px">
        <div style="margin-top: 30px;display: inline-block">
      <el-avatar style="height: 100px;width: 100px" shape="square" :src="userInfo.avatar"></el-avatar>
        </div>
        <el-dropdown trigger="click">
  <span style="  cursor: pointer; color: #409EFF;">
    {{userInfo.name !=null ?userInfo.name:userInfo.username}}<i class="el-icon--right" style="font-size: 12px;"></i>
  </span>
          <el-dropdown-menu slot="dropdown" style="margin-top: -40px">
            <el-dropdown-item>
              <div @click="gotoUserInfo" style="width: 100px;text-align: center">
                个人中心
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div @click="gotoMyChat" style="width: 100px;text-align: center">
                聊天室
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div @click="gotoMyBlog" style="width: 100px;text-align: center">
                读书心得
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div @click="gotoFans" style="width: 100px;text-align: center">
                粉丝
              </div></el-dropdown-item>
            <el-dropdown-item>
              <div @click="gotoFollows" style="width: 100px;text-align: center">
                关注
              </div></el-dropdown-item>
            <el-dropdown-item>
              <div @click="logout" style="width: 100px;text-align: center">
                退出
              </div></el-dropdown-item>
            <el-dropdown-item>
              <div @click="gotoLike" style="width: 100px;text-align: center">
                喜欢
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div @click="gotoCollect" style="width: 100px;text-align: center">
                收藏
              </div></el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>


    <!--    侧边栏和主体-->
    <div style="display: flex">
      <!--      侧边栏导航，$router.path：表示数据显示的是当前路由，router，表示菜单就是导航，
                   calc：-号两边要有空格-->

      <div style="width: 200px; min-height: calc( 100vh - 150px );margin-right: 2px;
            overflow: hidden;background-color: white">
        <el-menu
            background-color="#545c64"
            text-color="#fff"
            active-text-color="#ffd04b"
            :default-active="this.$route.path" router class="el-menu-demo">
          <el-menu-item index="/">
            <i class="el-icon-basketball"></i>
            <span>首页</span>
          </el-menu-item>
<!--          <el-submenu index="1">-->
<!--            <template slot="title">技术尝鲜</template>-->
<!--            <el-menu-item index="/about">画像检测和搜索</el-menu-item>-->
<!--          </el-submenu>-->
<!--          <el-submenu index="2">-->
<!--            <template slot="title">个性化推荐</template>-->
<!--            <el-menu-item index="/recommend">猜你喜欢</el-menu-item>-->
<!--            <el-menu-item index="/friend">好友们在看</el-menu-item>-->
<!--          </el-submenu>-->
          <el-menu-item index="/recommend">
            <i class="el-icon-question"></i>
            <span>猜你喜欢</span>
          </el-menu-item>
          <el-menu-item index="/coustomer">
            <i class="el-icon-school"></i>
            <span>社区</span>
          </el-menu-item>
          <el-submenu index="3">
            <template slot="title">

              <i class="el-icon-collection"></i>
              图书中心
            </template>

            <el-menu-item index="/main">图书展览</el-menu-item>
            <el-menu-item index="/books">中外图书Top250</el-menu-item>
          </el-submenu>
          <el-menu-item index="/searchuser">
            <i class="el-icon-s-check"></i>
            <span>用户搜索</span>
          </el-menu-item>
          <el-menu-item index="/user" v-if="hasRole">
            <template>
              <i class="el-icon-user-solid"></i>
              <span>用户管理</span>
            </template>

          </el-menu-item>
          <el-menu-item index="/booksedit" v-if="hasRole">
            <template>
              <i class="el-icon-edit-outline"></i>
              <span>图书管理</span>
            </template>
          </el-menu-item>
          <el-menu-item index="/category" v-if="hasRole">
            <template>
              <i class="el-icon-menu"></i>
              <span>分类管理</span>
            </template>
          </el-menu-item>
        </el-menu>
      </div>

      <!--      主体数据,flex:1，除200px，剩下的都给他（他：主体）-->
      <div style="flex: 1;background-color: white;padding: 10px;">
        <router-view/>
      </div>
    </div>

  </div>
</template>


<script>
import request from "@/utils/request";

export default {
  name: 'Layout',
  data(){
    return{
      userInfo:{
        name: '',
        avatar: ''
      },
      active: 'home',
      hasRole: false
    }
  },
  created() {
    this.userInfo.name = JSON.parse(localStorage.getItem("user")).name
    this.userInfo.avatar = JSON.parse(localStorage.getItem("user")).avatar
    this.userInfo.username = JSON.parse(localStorage.getItem("user")).username
    this.loadRole()
  },
  methods:{
    loadRole(){
        request.get(`/user/getrole/${localStorage.getItem("token")}`).then(res=>{
          if(res.code=='200'){
            // console.log(res.data)
            if(res.data === 'ROLE_ADMIN'){
              this.hasRole = true
            }
          }
        }).catch(err=>{
          console.log(err)
        })
    },
    gotoMyChat(){
      this.$router.push("/chat")
    },
    gotoFans(){
      this.$router.push("/fans")
    },
    gotoFollows(){
      this.$router.push("/follows")
    },
    gotoUserInfo(){
      this.$router.push("/userinfo")
    },
    gotoMyBlog(){
      this.$router.push("/myblog")
    },
    logout(){
      request.get(`/user/logout/${JSON.parse(localStorage.getItem("user")).id}/${localStorage.getItem("token")}`).then(res=>{
        if(res.code=='200'){
          this.$message.info("退出成功")
        }
      }).catch(err=>{
        console.log(err)
      })
      localStorage.removeItem("user")
      localStorage.removeItem("token")
      this.$router.push("/login")

    },
    gotoLike(){
      this.$router.push("/like")
    },
    gotoCollect(){
      this.$router.push("/collect")
    }
  }
}
</script>
