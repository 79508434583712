<template>
  <div>
    <div style="display: flex">
      <div style="flex: 1">
        <div style="text-align: center">消息</div>
        <el-card class="box-card">
          <div v-for="item in dataList" :key="item.customerId" class="text item" @click="gotoChat(item)">
            <div style="display: flex">
              <div style="flex: 1">
                <el-avatar :src="item.url"></el-avatar>
              </div>
              <div style="flex: 10">
                <div style="height: 50%;font-weight: bold">
                  {{item.name}}
                  <div style="display: inline-block;float: right">
                    {{item.inputtime}}
                  </div>
                </div>
                <div style="height: 50%">
                  {{item.lastContent}}</div>
              </div>
            </div>
            <el-divider></el-divider>
          </div>

        </el-card>
      </div>
      <div style="flex: 2">
        <ChatDetail ref="child"/>
      </div>
    </div>
  </div>
</template>

<script>
import ChatDetail from "@/views/ChatDetail.vue";
import request from "@/utils/request";

export default {
  name: 'Chat',
  components: {ChatDetail},
  data(){
    return{
      dataList: []
    }
  },
  created() {
    this.loadData()
  },
  provide(){
    return{
      fatherMethod: this.loadData
    }
  },
  methods:{
    gotoChat(item){
      // console.log(item)
      localStorage.setItem("customer",JSON.stringify(item))
      this.$refs.child.getInfo()
      this.$refs.child.loadData()
    },
    loadData(){
      request.get(`/chat/my/${JSON.parse(localStorage.getItem("user")).id}`).then(res=>{
        if(res.code=='200'){
          // console.log(res.data)
          this.dataList = res.data
        }
      })
    }
  }
}
</script>

<style>
.text {
  font-size: 14px;
}

.item {
  padding: 18px 0;
}

.box-card {
  width: 480px;
}

</style>
