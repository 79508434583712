<template>
  <div style="background-color: #909399">
    <div style="display: flex;background-color: white">
      <el-input style="width: 20%;margin-right: 10px" v-model="param.name" placeholder="请输入关键词"></el-input>
<!--      <el-input style="width: 20%;margin-right: 10px" v-model="param.author" placeholder="请输入作者"></el-input>-->
      <el-button @click="param.name = ''">清空</el-button>
      <el-button type="primary" @click="loadData">查询</el-button>
<!--      <div>-->
<!--        <el-button @click="priceUp">价格↑</el-button>-->
<!--        <el-button @click="priceDown">价格↓</el-button>-->
<!--        <el-button @click="ratingUp">评分↑</el-button>-->
<!--        <el-button @click="ratingDown">评分↓</el-button>-->
<!--        <el-button @click="china">中国</el-button>-->
<!--        <el-button @click="foreign">外国</el-button>-->
<!--      </div>-->
    </div>
    <el-row
        style="margin-bottom: 20px"
        :gutter="20"
    >
      <el-col v-for="(item,index) in datalist" :key="index" :span="6">
        <el-card shadow="hover" :body-style="{ padding: '0px' }" style="margin-bottom: 20px" class="projectCardInfo">
<!--          图书封面-->
          <el-image :src="item.url"></el-image>
<!--          图书信息-->
          <div class="contextInfoStyle">
            <span class="projectNameStyle"></span>
            <div class="contentStyle">
              <div>书名：{{item.name}}<span style="float: right" >
                <el-popover
                    placement="right"
                    width="400"
                    title="点击任意位置关闭"
                    trigger="click">
                  <el-card>
                    <div>作者：{{item.author}}</div>
              <div><span>价格：{{item.price}}元</span><span style="margin-left: 50px">评分：</span>{{item.rating}}</div>
              <div>出版社：{{item.press}} / {{item.date}}</div>
              <div>描述：{{item.description}}</div>
</el-card>
  <el-link type="success" slot="reference">更多</el-link>
</el-popover>

              </span></div>
              <div>作者：{{item.author}}</div>
              <div><span>价格：{{item.price}}元</span><span style="margin-left: 50px">评分：</span>{{item.rating}}</div>
              <div>出版社：{{item.press}} / {{item.date}}</div>
              <div>描述：{{item.description}}</div>
<!--              <div v-if="item.rate">-->
<!--                <el-rate-->
<!--                    v-model="item.rate"-->
<!--                 >-->
<!--                </el-rate>-->
<!--              </div>-->
            </div>
          </div>

<!--          <el-button type="text" @click="submitRate(item)">点击评分</el-button>-->
        </el-card>


      </el-col>
    </el-row>

    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="param.pageNum"
        :page-size="param.pageSize"
        layout="total, prev, pager, next"
        :total="total">
    </el-pagination>


<!--    分页-->
<!--    <el-pagination-->
<!--        @current-change="handleCurrentChange"-->
<!--        :current-page="this.param.pageNum"-->
<!--        :page-size="8"-->
<!--        layout="total, prev, pager, next"-->
<!--        :total="this.total">-->
<!--    </el-pagination>-->


<!--    评分表-->
    <el-dialog
        :title=this.title
        :visible.sync="dialogVisible"
        width="30%">
      <el-rate
          v-model="value2"
          :colors="colors">
      </el-rate>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="submit">确 定</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: 'Books',
  data(){
    return {
      page: 1,
      datalist: [],
      total: 100,
      value2: 5,
      title: "",
      book_rate_id: 0,
      userId: 0,
      colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
      dialogVisible: false,
      param: {
        userId: 0,
        name: '',
        author: '',
        pageNum: 1,
        pageSize: 8
      },
      userrate: {
        rate: 0
      }
    }
  },
  created() {
    this.loadData()
  },
  methods:{

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.param.pageSize=val
      this.loadData()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.param.pageNum=val
      this.loadData()
    },
    // handleCurrentChange(val){
    //   console.log(`当前页：${val}`)
    //   this.param.pageNum = val
    //   this.loadData()
    // },
    loadData(){
      this.param.userId = JSON.parse(localStorage.getItem("user")).id
      request.get("/book/list",{
        params: this.param
      }).then(res=>{
        if(res.code=='200'){
          console.log(res)
          this.datalist = res.data.data.list
          this.total = res.data.data.total
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    priceUp(){
      request.get("/book/priceUp").then(res=>{
        if(res.code=='200'){
          this.datalist = res.data
        }
      })
    },
    priceDown(){
      request.get("/book/priceDown").then(res=>{
        if(res.code=='200'){
          this.datalist = res.data
        }
      })
    },
    ratingUp(){
      request.get("/book/ratingUp").then(res=>{
        if(res.code=='200'){
          this.datalist = res.data
        }
      })
    },
    ratingDown(){
      request.get("/book/ratingDown").then(res=>{
        if(res.code=='200'){
          this.datalist = res.data
        }
      })
    },
    china(){
      request.get("/book/china").then(res=>{
        if(res.code=='200'){
          this.datalist = res.data
        }
      })
    },
    foreign(){
      request.get("/book/foreign").then(res=>{
        if(res.code=='200'){
          this.datalist = res.data
        }
      })
    },
    showComments(item){
      console.log(item)
      // this.$message.success(scope)
    },
    submitRate(item){
      this.book_rate_id = item.id
      console.log(item.name)
      this.title = "请给《"+item.name+"》打分"
      this.dialogVisible = true
},
    submit(){
      console.log(this.value2)
      this.userId = JSON.parse(localStorage.getItem("user")).id
      let userrate = { userId: this.userId, bookId: this.book_rate_id, rate: this.value2 }
      request.post("/user/rate/insert",userrate).then(res=>{
        if(res.code=='200'){
          this.$message.success("评分成功")
          this.loadData()
          this.dialogVisible = false
        }else {
          this.$message.error(res.msg)
          this.dialogVisible = false
        }
      }).catch(err=>{
        console.log(err)
        this.dialogVisible = false
      })
    }
  }
}
</script>

<style>
.projectCardInfo {
  cursor: pointer;
  height: 200px;
  .el-image {
    width: 100%;
    height: 160px;
    display: block;
  };
}
.projectNameStyle{
  line-height: 20px;
  font-weight: 500;
}
.projectNameStyle:hover{
  color: #0eb0f5;
}
.contentStyle{
  font-size: 15px;
  color: rgba(12, 11, 11, 0.45);
  line-height: 22px;
  text-align: left;
  overflow: hidden;
}
.contextInfoStyle{
  padding: 14px;
  text-align: left;
  line-height: 40px;
}
.userTimeStyle{
  display: flex;
  line-height: 26px;
  margin: 0 14px;
  font-size: 13px;
  font-weight: 200;
  .el-avatar {
    width: 24px;
    height: 24px;
    line-height: 24px;
    margin-left: -8px;
  }
  .el-avatar:first-child(){
    margin-left: 0;
  }
}
</style>
