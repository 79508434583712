<template>
  <div>
    <div class="chat_commento">
      <!-- top -->
      <div class="chat_top">
        {{userInfo.name}}
      </div>
      <!-- line -->
      <div class="line"></div>
      <!-- middle -->
      <div class="chat_middle" id="chat_middle_item">
        <div v-for="item in dataList" :key="item.id" >
          <!-- 左边 -->
          <div class="chat_left clearfix" v-if="item.sendId == userInfo.customerId">
            <div class="chat_left_item_1 ">
              <el-avatar :src="userInfo.url"></el-avatar>
            </div>
            <div class="chat_left_item_2">
              <div class="chat_time">{{item.inputtime}}</div>
              <div class="chat_left_content">
                {{item.content}}
              </div>
            </div>
          </div>
          <!--右边 -->
          <div class="chat_right" v-if="item.sendId == myInfo.id">
            <div class="chat_right_item_1">
              <el-avatar :src="myInfo.avatar"></el-avatar>
            </div>
            <div class="chat_right_item_2 ">
              <div class="chat_right_time">{{item.inputtime}}</div>
              <div class="chat_right_content">
                {{ item.content }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- line -->
<!--      <div class="line"></div>-->
      <!-- foot -->
      <div class="chat_foot">
        <!-- context -->
        <textarea v-model="param.content" class="chat_context" id="chat_context_item" cols="30" rows="10" placeholder="请输入"></textarea>
        <div class="chat_commit" id="button" @click="submit()">发送</div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: 'ChatDetail',
  data(){
    return{
      cosId: 2, //客户id，在左边
      param: {
        content: '',
        sendId: 0,
        receiveId: 0, //视情况而定
      },
      dataList: [],
      userInfo: {

      },
      myInfo:{

      }
    }
  },
  created() {
    this.loadData()
    this.getInfo()
  },
  inject: ['fatherMethod'],
  methods: {
    getInfo(){
      this.userInfo = JSON.parse(localStorage.getItem("customer"))
      this.myInfo = JSON.parse(localStorage.getItem("user"))
    },
    loadData(){
      request.get(`/chat/info/${JSON.parse(localStorage.getItem("user")).id}/${JSON.parse(localStorage.getItem("customer")).customerId}`).then(res=>{
        if(res.code=='200'){
          console.log(res.data)
          this.dataList = res.data
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    submit(){
      console.log(this.param)
      this.param.sendId = this.myInfo.id
      this.param.receiveId = this.userInfo.customerId
      request.post("/chat/save",this.param).then(res=>{
        if(res.code=='200'){
          console.log(res.data)
          this.loadData()
          this.param.content = ''
          this.fatherMethod()
        }
      }).catch(err=>{
        console.log(err)
      })
    }
  },
  watch:{

  }
}
</script>

<style>
*{
  padding: 0;
  margin: 0;
}
.chat_commento{
  width: 450px;
  height: 650px;
  margin: auto;
  border-radius: 10px;
  border: 2px solid #f4f5f7;
}
.clearfix::after{
  content: "";
  display: block;
  clear: both;
  width: 0;
  height: 0;
  line-height: 0;
  visibility: hidden;
}
/* top */
.chat_top{
  width: 100%;
  height: 50px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-left: 20px;
  font-size: 20px;
  line-height: 50px;
  box-sizing: border-box;
  font-weight: 550;
  border-width: 0px;
}
/* middle */
/* 左边 */
.chat_middle{
  width: 450px;
  height: 455px;
  position: relative;
  box-sizing: border-box;
  overflow: auto;
  border-width: 0px;
}
.chat_left{
  width: 100%;
  height: 120px;
  margin-top: 20px;
}
.chat_left_item_1{
  width: 50px;
  height: 50px;
  //background-color: #4459AB;
  float: left;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  line-height: 50px;
  color: white;
  border-radius: 25px;
}
.chat_left_item_2{
  width: 55%;
  height: 100px;
  float: left;
  margin-top: 10px;
}
.chat_left_item_2 .chat_left_chat{
  float: left;
}
.chat_left_item_2 .chat_left_content{
  padding: 15px;
  margin-top: 10px;
  background-color: #f4f5f7;
  display: inline-block;
  border-radius: 10px;
  border-top-left-radius: 0px;
}
/* 右边 */
.chat_right{
  width: 100%;
  height: 120px;
  margin-top: 20px;
}
.chat_right_item_1{
  width: 50px;
  height: 50px;
  //background-color: #4459AB;
  float: right;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  line-height: 50px;
  color: white;
  border-radius: 25px;
}
.chat_right_item_2{
  width: 55%;
  height: 100px;
  float: right;
  margin-top: 10px;
}
.chat_right_time{
  width: 100%;
  text-align: right;
}
.chat_right_content{
  float: right;
  padding: 15px;
  border-radius: 10px;
  margin-top: 10px;
  border-top-right-radius: 0px;
  background-color: #4F7cff;
  color: white;
}
/* foot */
.chat_foot{
  width: 450px;
  height: 130px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
}
.chat_context{
  width: 100%;
  height: 100%;
  font-size: 17px;
  box-sizing: border-box;
  outline: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-width: 0px;
  padding: 16px;
}
.chat_commit{
  width: 80px;
  height: 30px;
  color: white;
  background-color: #4F7cff;
  line-height: 30px;
  text-align: center;
  border-radius: 5px;
  position: absolute;
  right: 10px;
  bottom: 20px;
  margin-right: 10px;
}
.chat_commit:hover{
  cursor: pointer;
  background-color: #0eb0f5;
}
.chat_context{
  resize: none;
}
.chat_context::placeholder{
  color: black;
  font-weight: 500k;
}
.line{
  width: 100%;
  border-top: 1px;
  border-color: #f4f5f7;
  border-style: solid;
}
</style>
