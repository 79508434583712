<template>
  <div>
    <el-link type="danger" @click="$router.go(-1)">返回上一级</el-link>
    <div>
      <el-card class="box-card">

        <div style="display: inline-block">
          <img style="width: 100px;height: 100px" :src="user.url"/>
          <span>姓名：{{user.name}}</span>
        </div>
        <div style="display: inline-block;margin-left: 20px">
          <span>总访问量：{{user.eyes}}</span>
          <el-divider direction="vertical"></el-divider>
          <span></span>
          <el-divider direction="vertical"></el-divider>
          <span>文章：{{user.blogs}}</span>
          <el-divider direction="vertical"></el-divider>
          <span>粉丝：{{user.fans}}</span>
          <el-divider direction="vertical"></el-divider>
          <span>关注：{{user.follows}}</span>
          <el-divider direction="vertical"></el-divider>
          <span>喜欢书籍数量：{{user.likes}}</span>
          <el-divider direction="vertical"></el-divider>
          <span>收藏书籍数量：{{user.collects}}</span>
          <el-divider direction="vertical"></el-divider>
          <el-button type="primary" v-if="$route.query.id!=currentuserId" @click="followBlogUser($route.query.id)" :icon="isFollow==0?'el-icon-plus':'el-icon-check'">{{isFollowText()}}</el-button>
          <el-button type="success" icon="el-icon-s-comment" v-if="this.isFollow==1" @click="sixin(user)">私信</el-button>


        </div>
      </el-card>
    </div>

  </div>
</template>



<script>
import router from "@/router";
import request from "@/utils/request";
import {triggerRef} from "vue";

export default {
  name: "UserFollow",
  data(){
    return{
      userId: 0,
      isFollow: 1,
      user: {
        name: '',
        url: '',
        blogs: 0,
        eyes: 0,
        follows: 0,
        fans: 0,
        likes: 0,
        collects: 0
      },
      currentuserId: JSON.parse(localStorage.getItem("user")).id
    }
  },
  created() {
    this.loadData()
  },
  methods:{
    // sixin(){
    //   // console.log("接收者id：")
    //   // console.log(this.$route.query.id)
    //   // console.log("发送者id：")
    //   // console.log(JSON.parse(localStorage.getItem("user")).id)
    //
    // },
    sixin(user) {
      let cus = {
        customerId: this.$route.query.id,
        name: user.name,
        url: user.url
      }
      localStorage.setItem("customer",JSON.stringify(cus))
      this.$router.push('/chat')
    },
    followBlogUser(userId){
      request.get(`/user/follow/update/${this.isFollow}/${this.$route.query.id}/${JSON.parse(localStorage.getItem("user")).id}`).then(res=>{
        if(res.code=='200'){
          this.$notify({
            title: '操作成功',
            type: 'success'
          })
          this.loadData()
        }
      }).catch(err=>{
        console.log(err)
      })
      console.log(userId)
    },
    isFollowText(){
      if(this.isFollow != 1){
        return "关注"
      }else{
        return "已关注"
      }
    },
      loadData() {
        this.userId = this.$route.query.id
        request.get(`/user/detail/${this.userId}`).then(res=>{
          if(res.code=='200'){
            console.log(res.data)
            this.user = res.data
          }
        }).catch(err=>{
          console.log(err)
        })
        request.get(`/user/follow/or/nor/${this.$route.query.id}/${JSON.parse(localStorage.getItem("user")).id}`).then(res=>{
          if(res.code=='200'){
            this.isFollow = 1
          }else {
            this.isFollow = 0
          }
        }).catch(err=>{
          console.log(err)
        })
    }
  }
}
</script>



<style>
.box-card {
  //width: 200px;
  margin: 0 auto;
}
</style>
