<template>
  <div>
    <div style="font-size: 24px;font-weight: bold;margin-left: 30px">尚未开发</div>
  </div>
</template>

<script>
export default {
  name: 'Friend'
}
</script>
