<template>
  <div>
    <h1 style="text-align: center">读书交流会</h1>
    <el-row style="margin-bottom: 20px" >
      <el-col :span="12" v-for="(item, index) in dataList" :key="index" style="padding-top: 10px">
        <el-card :body-style="{ padding: '0px',height: '220px' }" style="position: relative;margin-left: 20px;margin-bottom: 20px">
          <div style="padding: 14px;">
            <div class="bottom clearfix" style="text-align: center;">
              <div style="font-size: 16px;margin-bottom:5px;font-weight: bold">{{item.title}}</div>

              <div style="margin-bottom:5px;"><span>发布日期：</span>{{item.inputtime}}<span style="margin-left: 5px">发布人：</span>

                <el-tag type="info">

                {{item.username}}</el-tag></div>
              <div style="margin-bottom:5px;"><span>图书名称：</span>{{item.bookName}}</div>
              <div style="margin-bottom:5px;"><span>图书分类：</span><el-tag>{{item.categoryName}}</el-tag></div>
              <div  style="margin-left: 2px;text-align: left;position: absolute;bottom: 0">
                <el-button type="primary" @click="getBlogInfo(item)">详情</el-button>
                <div style="display: inline-block;margin-left: 10px">
                  <i class="el-icon-view"><span style="margin-left: 3px">{{item.eye}}</span></i>
                  <el-image @click="likeBlog(item)" :src="item.isLike == 0 ?require('../assets/爱心.png'):require('../assets/爱心 (1).png')" style="cursor: pointer;margin-left: 5px;width: 20px;height: 18px"></el-image><span>{{item.love}}</span>
                </div>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>

    </div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: 'Coustomer',
  data(){
    return{
      dataList: []
    }
  },
  created() {
    this.loadData()
  },
  methods:{
    loadData(){
      request.get(`/blog/list/${JSON.parse(localStorage.getItem("user")).id}`).then(res=>{
        if(res.code=='200'){
          this.dataList = res.data
          console.log(res.data)
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    getBlogInfo(item){
      // console.log(item.id)
      //增加浏览量
      if(item.userId!=JSON.parse(localStorage.getItem("user")).id){
        let id = item.id
        request.post(`/blog/updateEye/${id}`).then(res=>{
          if(res.code=='200'){
            console.log(res)
          }
        })
      }
      let id = item.id
      this.$router.push({
        path: '/article',
        query: {
          id
        }
      })
    },
    likeBlog(item){
      console.log(item)
      request.get(`/blog/like/${item.id}/${JSON.parse(localStorage.getItem("user")).id}`).then(res=>{
        if(res.code=='200'){
          console.log("成功")
          this.loadData()
        }else {
          console.log("失败")
        }
      }).catch(err=>{
        console.log(err)
      })
    }
  }
}
</script>


