<template>
  <div>
    <div><el-link @click="$router.go(-1)">返回上一级</el-link></div>
    <div style="text-align: center;line-height: 40px">
      <div style="font-size: 24px">{{blog.title}}</div>
      <div style="text-align: center">心得作者：
        <el-popover
            placement="top-start"
            title="用户详情"
            width="200"
            trigger="hover"
            :content="userFollowInfoya">
          <el-tag type="danger" style="cursor: pointer" slot="reference">{{blog.username}}</el-tag>
        </el-popover>

        <span style="margin-left: 30px"><el-button v-if="blog.userId!=currentuserId" @click="followBlogUser(blog.userId)" :icon="isFollow==0?'el-icon-plus':'el-icon-check'">{{isFollowText()}}</el-button></span></div>
      <div>书名：<el-tag type="success">{{blog.bookName}}</el-tag></div>
      <span>{{blog.inputtime}}</span><span style="margin-left:  20px"><el-tag>{{blog.categoryName}}</el-tag></span>
<!--      <div style="display: inline-block;margin-left: 30px">-->
<!--        <i class="el-icon-view"><span style="margin-left: 3px">{{blog.eye}}</span></i>-->
<!--        <img src="../assets/点赞.png" style="margin-left: 5px;width: 20px;height: 18px"><span>{{blog.love}}</span>-->
<!--      </div>-->
    </div>
    <div>
      <div v-html="blog.content"></div>
    </div>

    <div style="margin: 0 auto;width: 70%">
      <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入您的见解"
          v-model="comment.content">
      </el-input>
      <div style="text-align: right">
        <el-button @click="submitComment" type="success">提交评论</el-button>
      </div>

    </div>

    <div style="margin: 20px 0">
      <div>评论列表</div>
      <el-divider></el-divider>
      <div style="padding: 10px 0" v-for="item in commentList" :key="item.id">
        <div style="display: flex">
          <div style="width: 60px">
            <el-avatar :src="item.url"></el-avatar>
          </div>
          <div style="flex: 1">
            <el-tag type="info" @click="follow(item.userId)" style="cursor: pointer">{{item.username}}
            </el-tag>
            <span style="margin-right: 10px">{{item.inputtime}}</span><el-tag type="warning" v-if="item.userId == blog.userId">楼主</el-tag>
            <span style="margin-left: 20px">{{item.ipAddress}}</span>
            <div style="margin-top: 10px">{{item.content}}</div>
            <!--        多级回复-->
            <div style="text-align: left">
              <el-button @click="reply(item.id,item.username)" type="text">回复</el-button>
            </div>

<!--            回复列表-->
            <div v-if="item.children.length>0" style="margin-left: 100px;background-color: #e1efef">
              <div v-for="sub in item.children" :key="sub.id">
                <div><span style="cursor: pointer" ><el-avatar :src="sub.url"></el-avatar><span @click="reply(sub.pid,sub.username)" >{{sub.username}}</span></span>   <span> 回复 <span style="color: #0eb0f5">@{{sub.target}} </span><span>{{sub.content}}</span> </span>
                  <div style="float: right">{{item.inputtime}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <el-dialog title="回复" :visible.sync="dialogFormVisible">
      <el-form :model="comment">
        <el-form-item>
          <el-input v-model="comment.content"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button @click="submitComment">提交评论</el-button>
      </div>
    </el-dialog>


  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: 'BlogDetail',
  data(){
    return{
      blog:{},
      isFollow: 1,
      comment: {
        blogId: this.$route.query.id,
        userId: JSON.parse(localStorage.getItem("user")).id
      }, //评论
      commentList: [],
      dialogFormVisible: false,
      userFollowInfoya: '',
      currentuserId: JSON.parse(localStorage.getItem("user")).id
    }
  },
  created() {
    this.getId()
    this.loadComment()
  },
  methods:{
    followBlogUser(userId){
      request.get(`/user/follow/update/${this.isFollow}/${this.blog.userId}/${JSON.parse(localStorage.getItem("user")).id}`).then(res=>{
        if(res.code=='200'){
          this.$notify({
            title: '操作成功',
            type: 'success'
          })
          this.getId()
        }
      }).catch(err=>{
        console.log(err)
      })
      // console.log(userId)
    },
    //用户关注数和粉丝数
    getUserFollow(){
      let detail = {
        fans: 0,
        follows: 0,
        likes: 0,
        collects: 0
      }
      request.get(`/user/follow/detail/${this.blog.userId}`).then(res=>{
        if(res.code=='200'){
          detail = res.data
          console.log("detail:")
          // console.log(detail)
          this.userFollowInfoya = `粉丝${detail.fans} | 关注${detail.follows} | 喜欢书籍${detail.likes} | 收藏书籍${detail.collects}`
        }
      }).catch(err=>{
        console.log(err)
        return "ss";
      })
    },
    isFollowText(){
      if(this.isFollow != 1){
        return "关注"
      }else{
        return "已关注"
      }
    },
    follow(id){
      this.$router.push({
        path: '/userfollow',
        query: {
          id
        }
      })
    },
    cancel(){
      this.comment = {}
      this.dialogFormVisible = false
    },
    reply(id,target){
      this.comment.pid = id
      this.comment.target = target // 回复对象
      this.dialogFormVisible = true
    },
    loadComment(){
      request.get(`/comment/list/${this.$route.query.id}`).then(res=>{
        if(res.code=='200'){
          this.commentList = res.data
          // console.log("data:")
          // console.log(res.data)
        }
      })
    },
    getId(){
      let userID1 = 0
      request.get(`/blog/blogInfo/${this.$route.query.id}`).then(res=>{
        if(res.code=='200'){
          this.blog = res.data
          console.log("用户ID位：")
          userID1 = this.blog.userId
          // console.log(this.blog.userId)
          request.get(`/user/follow/or/nor/${userID1}/${JSON.parse(localStorage.getItem("user")).id}`).then(res=>{
            if(res.code=='200'){
              this.isFollow = 1
            }else {
              this.isFollow = 0
            }
          }).catch(err=>{
            console.log(err)
          })
        }

        this.getUserFollow()
      }).catch(err=>{
        console.log(err)
      })

    },
    submitComment(){
      this.comment.blogId = this.$route.query.id
      this.comment.userId = JSON.parse(localStorage.getItem("user")).id
      request.post("/comment/save",this.comment).then(res=>{
        if(res.code == '200'){
          this.$message.success("提交评论成功")
          this.comment = {}
          this.loadComment()
          this.dialogFormVisible = false
        }
      }).catch(err=>{
        console.log(err)
      })
    }
  }
}
</script>

<style>
.el-header, .el-footer {
  color: #333;
  text-align: center;
  line-height: 100px;
}

.el-aside {
  background-color: #D3DCE6;
  color: #333;
  text-align: center;
  line-height: 200px;
}


</style>
