<template>
  <div>
    <div style="font-size: 24px;font-weight: bold;margin-left: 30px"><span style="margin-right: 50px">猜你喜欢</span>
      <el-link @click="change" type="warning" style="font-size: 20px"><i class="el-icon-refresh"></i>换一换</el-link>
    </div>

    <el-table
        :data="tableData"
        style="width: 100%">
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-form label-position="left" inline class="demo-table-expand">
            <el-form-item label="图书名称">
              <span>{{ props.row.name }}</span>
            </el-form-item>
            <el-form-item label="图书作者">
              <span>{{ props.row.author }}</span>
            </el-form-item>
            <el-form-item label="价格">
              <span>{{ props.row.price }}</span>
            </el-form-item>
            <el-form-item label="出版社">
              <span>{{ props.row.press }}</span>
            </el-form-item>
            <el-form-item label="出版时间">
              <span>{{ props.row.date }}</span>
            </el-form-item>
            <el-form-item label="图书分类">
              <span>{{ props.row.categoryName }}</span>
            </el-form-item>
            <el-form-item label="推荐指数">
              <span>{{ props.row.recommend }}</span>
            </el-form-item>
            <el-form-item label="图书封面">
              <el-image :src="props.row.url"></el-image>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column
          label="图书名称"
          prop="name">
      </el-table-column>
      <el-table-column
          label="作者"
          prop="author">
      </el-table-column>
      <el-table-column
          label="价格"
          prop="price">
      </el-table-column>
    </el-table>

  </div>
</template>


<script>
import request from "@/utils/request";

export default {
  name: "Recommend",
  data(){
    return{
      tableData: [],
      changes: 0
    }
  },
  created() {
    this.changes = 0
    this.loadData()
  },
  methods:{
    loadData(){
      request.get(`/books/list/${JSON.parse(localStorage.getItem("user")).id}/${this.changes}`).then(res=>{
        if(res.code=='200'){
          console.log(res.data)
          this.tableData = res.data
        }
      })
    },
    change(){
      //如果change=1，将刷新，否则选择最高位
      this.changes = 1
      this.loadData()
    }
  }
}
</script>

<style>
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>

