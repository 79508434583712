<template>
  <div class="main-content">
    <div>
      <div>
        <div>
          <el-input
              style="display: inline-block;width: 20%;margin-right: 10px"
              placeholder="请输入图书名字"
              v-model="params.name"
              clearable>
          </el-input>
          <el-input
              style="display: inline-block;width: 20%;margin-right: 10px"
              placeholder="请输入图书作者"
              v-model="params.author"
              clearable>
          </el-input>
          <el-select v-model="value" placeholder="请选择图书分类">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
<!--        <el-button :type='color1' @click="val1">计算机/网络</el-button>-->
<!--        <el-button :type="color2" @click="val2">小说</el-button>-->
<!--        <el-button :type="color3"  @click="val3">两性关系</el-button>-->
<!--        <el-button :type="color4"  @click="val4">历史</el-button>-->
<!--        <el-button :type="color5"  @click="val5">医学</el-button>-->
<!--        <el-button :type="color6" @click="val6">法律</el-button>-->
<!--        <el-button :type="color7"  @click="val7">投资理财</el-button>-->
<!--        <el-button :type="color8"  @click="val8">体育/运动</el-button>-->
<!--        <el-button :type="color9"  @click="val9">时尚/美妆</el-button>-->
<!--        <el-button :type="color10"  @click="val10">外语</el-button>-->
<!--        <el-button :type="color11"  @click="val11">心理</el-button>-->
<!--        <el-button :type="color12"  @click="val12">旅游</el-button>-->
<!--        <el-button :type="color13"  @click="val13">动漫/幽默</el-button>-->

      </div>
      <el-table :data="tableData" stripe height="400"  style="width: 100%">
        <el-table-column width="200" label="图书封面">
          <template slot-scope="scope">
            <el-image :src="scope.row.url"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="名称"    width="150"></el-table-column>
        <el-table-column prop="author" label="作者"      width="150"></el-table-column>
        <el-table-column prop="price" label="价格"     width="100"></el-table-column>
        <el-table-column prop="press" label="出版社"     width="150"></el-table-column>
        <el-table-column label="出版时间"  width="150">
          <template slot-scope="scope">
            <i class="el-icon-time"></i>
            <span style="margin-left: 10px">{{ scope.row.date }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="recommend" label="推荐指数"    width="100"></el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            width="150">
          <template slot-scope="scope">
            <el-button @click="handleClickLike(scope.row)" :type="scope.row.like==1?'danger':'info'" size="mini">喜欢</el-button>
            <el-button @click="handleClickCollect(scope.row)" :type="scope.row.collect==1?'warning':'info'" size="mini">收藏</el-button>
          </template>
        </el-table-column>
      </el-table>


      <div style="margin-top: 20px">
        <el-pagination
            background
            :page-size="this.params.pageSize"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="this.params.pageNum"
            :page-sizes="[2, 5, 10, 20, 100]"
            layout="total,prev,pager,next,sizes,jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>

import request from "@/utils/request";

export default {
  name: "Main",
  data(){
    return{
      tableData: [],
      params: {
        name: '',
        author: '',
        categoryId: 1,
        pageNum: 1,
        pageSize: 10,
        userId: JSON.parse(localStorage.getItem("user")).id
      },
      allcolor: 'info',
      color1: 'info',
      color2: 'info',
      color3: 'info',
      color4: 'info',
      color5: 'info',
      color6: 'info',
      color7: 'info',
      color8: 'info',
      color9: 'info',
      color10: 'info',
      color11: 'info',
      color12: 'info',
      color13: 'info',
      total: 0,
      options: [],
      value: 1
    }
  },
  created() {
    this.color1 = 'primary'
    this.value = this.params.categoryId
    console.log(this.value)
    this.loadData()
    this.loadOption()
  },
  watch: {
    value(newval,oldval){
      this.params.categoryId = newval
      this.params.pageNum = 1
      // localStorage.setItem("cid",newval)
      this.loadData()
    },
    "params.name":{
      handler(newval,oldval){
        this.params.pageNum = 1
        this.params.name = newval
        this.loadData()
      }
    },
    "params.author":{
      handler(newval,oldval){
        this.params.pageNum = 1
        this.params.author = newval
        this.loadData()
      }
    }
  },
  methods:{
    loadOption(){
      request.get("/book/option").then(res=>{
        if(res.code=='200'){
          this.options = res.data
          console.log(res)
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    loadData(){
      request.get("/books/page",{
        params: this.params
      }).then(res=>{
        if(res.code=='200'){
          console.log(res.data.list)
          this.tableData = res.data.list
          this.total = res.data.total
        }
      })
    },
    handleClickLike(scope){
      if(scope.like==1){
        var like1 = 0
      }else{
        var like1 = 1
      }
      let param = {userId: JSON.parse(localStorage.getItem("user")).id,bookId: scope.id,like: like1,categoryId: scope.categoryId}
      request.post("user/collect/add/like",param).then(res=>{
        if(res.code=='200'){
          this.$message.success("喜欢成功")
          this.loadData()
        }
      }).catch(err=>{
        this.$message.error("操作失败")
      })
     },
    handleClickCollect(scope){
      if(scope.collect==1){
        var collect1 = 0
      }else {
        var collect1 = 1
      }
      let param = {userId: JSON.parse(localStorage.getItem("user")).id,bookId: scope.id,collect: collect1,categoryId: scope.categoryId}
      request.post("user/collect/add/collect",param).then(res=>{
        if(res.code=='200'){
          this.$message.success("收藏成功")
          this.loadData()
        }
      }).catch(err=>{
        this.$message.error("操作失败")
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.params.pageSize=val
      this.loadData()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.params.pageNum=val
      this.loadData()
    },
    all(){
      request.get("/books/list",{
        params: this.params
      }).then(res=>{
        if(res.code=='200'){
          this.allcolor = 'success'
          this.color1 = 'info'
          this.color2 = 'info'
          this.color3 = 'info'
          this.color4 = 'info'
          this.color5 = 'info'
          this.color6 = 'info'
          this.color7 = 'info'
          this.tableData = res.data.list
          this.total = res.data.total
        }
      })
    },
    val1(){
      this.params.categoryId = 1
      this.allcolor = 'info'
      this.color1 = 'primary'
      this.color2 = 'info'
      this.color3 = 'info'
      this.color4 = 'info'
      this.color5 = 'info'
      this.color6 = 'info'
      this.color7 = 'info'
      this.color8 = 'info'
      this.color9 = 'info'
      this.color10 = 'info'
      this.color11 = 'info'
      this.color12 = 'info'
      this.color13 = 'info'
      this.loadData()
    },
    val2(){
      this.params.categoryId = 2
      this.allcolor = 'info'
      this.color1 = 'info'
      this.color2 = 'primary'
      this.color3 = 'info'
      this.color4 = 'info'
      this.color5 = 'info'
      this.color6 = 'info'
      this.color7 = 'info'
      this.color8 = 'info'
      this.color9 = 'info'
      this.color10 = 'info'
      this.color11 = 'info'
      this.color12 = 'info'
      this.color13 = 'info'
      this.loadData()
    },
    val3(){
      this.params.categoryId = 3
      this.allcolor = 'info'
      this.color1 = 'info'
      this.color2 = 'info'
      this.color3 = 'primary'
      this.color4 = 'info'
      this.color5 = 'info'
      this.color6 = 'info'
      this.color7 = 'info'
      this.color8 = 'info'
      this.color9 = 'info'
      this.color10 = 'info'
      this.color11 = 'info'
      this.color12 = 'info'
      this.color13 = 'info'
      this.loadData()
    },
    val4(){
      this.params.categoryId = 4
      this.allcolor = 'info'
      this.color1 = 'info'
      this.color2 = 'info'
      this.color3 = 'info'
      this.color4 = 'primary'
      this.color5 = 'info'
      this.color6 = 'info'
      this.color7 = 'info'
      this.color8 = 'info'
      this.color9 = 'info'
      this.color10 = 'info'
      this.color11 = 'info'
      this.color12 = 'info'
      this.color13 = 'info'
      this.loadData()
    },
    val5(){
      this.params.categoryId = 5
      this.allcolor = 'info'
      this.color1 = 'info'
      this.color2 = 'info'
      this.color3 = 'info'
      this.color4 = 'info'
      this.color5 = 'primary'
      this.color6 = 'info'
      this.color7 = 'info'
      this.color8 = 'info'
      this.color9 = 'info'
      this.color10 = 'info'
      this.color11 = 'info'
      this.color12 = 'info'
      this.color13 = 'info'
      this.loadData()
    },
    val6(){
      this.params.categoryId = 6
      this.allcolor = 'info'
      this.color1 = 'info'
      this.color2 = 'info'
      this.color3 = 'info'
      this.color4 = 'info'
      this.color5 = 'info'
      this.color6 = 'primary'
      this.color7 = 'info'
      this.color8 = 'info'
      this.color9 = 'info'
      this.color10 = 'info'
      this.color11 = 'info'
      this.color12 = 'info'
      this.color13 = 'info'
      this.loadData()
    },
    val7(){
      this.params.categoryId = 8
      this.allcolor = 'info'
      this.color1 = 'info'
      this.color2 = 'info'
      this.color3 = 'info'
      this.color4 = 'info'
      this.color5 = 'info'
      this.color6 = 'info'
      this.color7 = 'primary'
      this.color8 = 'info'
      this.color9 = 'info'
      this.color10 = 'info'
      this.color11 = 'info'
      this.color12 = 'info'
      this.color13 = 'info'
      this.loadData()
    },
    val8(){
      this.params.categoryId = 9
      this.allcolor = 'info'
      this.color1 = 'info'
      this.color2 = 'info'
      this.color3 = 'info'
      this.color4 = 'info'
      this.color5 = 'info'
      this.color6 = 'info'
      this.color7 = 'info'
      this.color8 = 'primary'
      this.color9 = 'info'
      this.color10 = 'info'
      this.color11 = 'info'
      this.color12 = 'info'
      this.color13 = 'info'
      this.loadData()
    },
    val9(){
      this.params.categoryId = 10
      this.allcolor = 'info'
      this.color1 = 'info'
      this.color2 = 'info'
      this.color3 = 'info'
      this.color4 = 'info'
      this.color5 = 'info'
      this.color6 = 'info'
      this.color7 = 'info'
      this.color8 = 'info'
      this.color9 = 'primary'
      this.color10 = 'info'
      this.color11 = 'info'
      this.color12 = 'info'
      this.color13 = 'info'
      this.loadData()
    },
    val10(){
      this.params.categoryId = 11
      this.allcolor = 'info'
      this.color1 = 'info'
      this.color2 = 'info'
      this.color3 = 'info'
      this.color4 = 'info'
      this.color5 = 'info'
      this.color6 = 'info'
      this.color7 = 'info'
      this.color8 = 'info'
      this.color9 = 'info'
      this.color10 = 'primary'
      this.color11 = 'info'
      this.color12 = 'info'
      this.color13 = 'info'
      this.loadData()
    },
    val11(){
      this.params.categoryId = 12
      this.allcolor = 'info'
      this.color1 = 'info'
      this.color2 = 'info'
      this.color3 = 'info'
      this.color4 = 'info'
      this.color5 = 'info'
      this.color6 = 'info'
      this.color7 = 'info'
      this.color8 = 'info'
      this.color9 = 'info'
      this.color10 = 'info'
      this.color11 = 'primary'
      this.color12 = 'info'
      this.color13 = 'info'
      this.loadData()
    },
    val12(){
      this.params.categoryId = 13
      this.allcolor = 'info'
      this.color1 = 'info'
      this.color2 = 'info'
      this.color3 = 'info'
      this.color4 = 'info'
      this.color5 = 'info'
      this.color6 = 'info'
      this.color7 = 'info'
      this.color8 = 'info'
      this.color9 = 'info'
      this.color10 = 'info'
      this.color11 = 'info'
      this.color12 = 'primary'
      this.color13 = 'info'
      this.loadData()
    },
    val13(){
      this.params.categoryId = 14
      this.allcolor = 'info'
      this.color1 = 'info'
      this.color2 = 'info'
      this.color3 = 'info'
      this.color4 = 'info'
      this.color5 = 'info'
      this.color6 = 'info'
      this.color7 = 'info'
      this.color8 = 'info'
      this.color9 = 'info'
      this.color10 = 'info'
      this.color11 = 'info'
      this.color12 = 'info'
      this.color13 = 'primary'
      this.loadData()
    },
  }
}
</script>



<style scoped>

</style>
