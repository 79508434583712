<template>
  <div>
    <div style="height: 50px;width: 100%;color: #0eb0f5;font-size: 24px;display: inline-block">
      <el-link  type="danger" @click="$router.push('/')">返回主页</el-link>
      <el-button style="float: right" type="warning" @click="$router.push('/collect')">收藏列表</el-button>
    </div>
    <div>
      <h1 style="text-align: center;color: red">喜欢</h1>
      <div style="display: flex">
        <el-input style="flex: 1;width: 100px" v-model="params.name" placeholder="请输入图书名"></el-input>
        <el-input style="flex: 1;width: 100px;padding-left: 10px" v-model="params.author" placeholder="请输入作者"></el-input>
        <div style="flex: 3;padding-left: 10px">
          <el-button type="primary" @click="loadData">查询</el-button>
        </div>
      </div>
      <el-table v-loading="loading"  element-loading-text="正在加载数据" :data="tableData" stripe>
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column prop="author" label="作者"></el-table-column>
        <el-table-column prop="price" label="价格"></el-table-column>
        <el-table-column prop="press" label="出版社"></el-table-column>
        <el-table-column prop="date" label="出版时间"></el-table-column>
        <el-table-column prop="recommend" label="推荐指数"></el-table-column>
        <el-table-column prop="categoryName" label="图书分类"></el-table-column>
<!--                <el-table-column width="200" label="图书封面">-->
<!--                  <template slot-scope="scope">-->
<!--                    <el-image :src="scope.row.url"></el-image>-->
<!--                  </template>-->
<!--                </el-table-column>-->
        <el-table-column
            fixed="right"
            label="操作"
            width="180">
          <template slot-scope="scope">
            <el-button @click="handleClickLike(scope.row)" :type="scope.row.like==1?'danger':'info'" size="mini">喜欢</el-button>
          </template>
        </el-table-column>
      </el-table>



    </div>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: 'Like',
  data(){
    return{
      tableData: [],
      params:{
        name: '',
        author: '',
        userId: JSON.parse(localStorage.getItem("user")).id
      },
      loading: false
    }
  },
  created() {
    this.loadData()
  },
  methods:{
    loadData2(){

    },
    loadData(){
      this.loading = true
      request.get("/books/like",{
        params: this.params
      }).then(res=>{
        if(res.code=='200'){
          this.loading = false
          console.log(res.data)
          this.tableData = res.data
        }
      }).catch(err=>{
        this.loading = false
        console.log("err=>"+err)
      })
    },
    handleClickLike(scope){
      if(scope.like==1){
        var like1 = 0
      }else{
        var like1 = 1
      }
      let param = {userId: JSON.parse(localStorage.getItem("user")).id,bookId: scope.id,like: like1,categoryId: scope.categoryId}
      request.post("user/collect/add/like",param).then(res=>{
        if(res.code=='200'){
          this.$message.success("喜欢成功")
          this.loadData()
        }
      }).catch(err=>{
        this.$message.error("操作失败")
      })
    }
  }
}

</script>

<style>

</style>
