import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Main from "@/views/Main.vue";
import Books from "@/views/Books.vue";
import Login from "@/login/Login.vue";
import Layout from "@/views/Layout.vue";
import Like from "@/views/Like.vue";
import Collect from "@/views/Collect.vue";
import Recommend from "@/views/Recommend.vue";
import Coustomer from "@/views/Coustomer.vue";
import UserInfo from "@/views/UserInfo.vue";
import MyBlog from "@/views/MyBlog.vue";
import BlogDetail from "@/views/BlogDetail.vue";
import UserFollow from "@/views/UserFollow.vue";
import Fans from "@/views/Fans.vue";
import Follows from "@/views/Follows.vue";
import Friend from "@/views/Friend.vue";
import Chat from "@/views/Chat.vue";
import User from "@/views/role/User.vue";
import Category from "@/views/role/Category.vue";
import Search from "@/views/Search.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/like',
    name: 'Like',
    component: Like
  },
  {
    path: '/collect',
    name: 'Collect',
    component: Collect
  },

  {
    path: '/',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: 'searchuser',
        name: 'Search',
        component: Search
      },
      {
        path: "category",
        name: 'Category',
        component: Category
      },
      {
        path: "/booksedit",
        name: "BookEdit",
        component: ()=>import("../views/role/Books.vue")
      },
      {
        path: 'user',
        name: 'User',
        component: User
      },
      {
        path: '/chat',
        name: 'Chat',
        component: Chat
      },
      {
        path: '/fans',
        name: 'Fans',
        component: Fans
      },
      {
        path: '/follows',
        name: 'Follows',
        component: Follows
      },
      {
        path: '/friend',
        name: 'Friend',
        component: Friend
      },
      {
        path: '/userfollow',
        name: 'userfollow',
        component: UserFollow,
        children:[
          {
            path: '/collect1',
            name: 'Collect1',
            component: () => import('../views/component/Collect.vue')
          },
          {
            path: '/like1',
            name: 'Like1',
            component: ()=>import('../views/component/Like.vue')
          },
          {
            path: '/xinde1',
            name: 'Xinde1',
            component: ()=>import('../views/component/Xinde.vue')
          }
        ]
      },
      {
        path: '/article',
        name: 'article',
        component: BlogDetail
      },
      {
        path: '/',
        name: 'home',
        component: HomeView
      },
      {
        path: 'userinfo',
        name: 'userinfo',
        component: UserInfo
      },
      {
        path: '/myblog',
        name: 'MyBlog',
        component: MyBlog
      },
      {
        path: '/coustomer',
        name: 'Coustomer',
        component: Coustomer
      },
      {
        path: '/recommend',
        name: 'Recommend',
        component: Recommend
      },
      {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
      },
      {
        path: '/main',
        name: 'main',
        component: Main
      },
      {
        path: '/books',
        name: 'books',
        component: Books
      }
    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error=> error)
}

router.beforeEach((to,from,next)=>{
  if(to.path === '/login') next()
  const admin = localStorage.getItem("user")
  if(!admin && to.path !== '/login')
    return next('/login')
  next()
})
export default router
