<template>
  <div>
    <el-input placeholder="请输入用户关键字"
              style="display: inline-block;width: 20%;margin-left: 20px"
              v-model="form.value"
              clearable></el-input>

    <el-divider></el-divider>

    <div>
      <el-table
          :data="gridData"
          border
          height="450"
          style="width: 100%">
        <el-table-column label="头像" width="150">
          <template slot-scope="scope">
            <el-image :src="scope.row.avatar" style="width: 70px;height: 70px"></el-image>
          </template>
        </el-table-column>
        <el-table-column
            prop="name"
            label="姓名"
            width="180">
        </el-table-column>
        <el-table-column
            prop="sex"
            label="性别">
          <template slot-scope="scope">
            <el-image style="width: 70px;height: 70px" :src="scope.row.sex==0?require('../../src/assets/男.png'):require('../../src/assets/女.png')"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" :type="scope.row.isFollow==0?'info':'primary'" v-if="scope.row.id!=currentuserId" @click="followBlogUser(scope.row.id,scope.row.isFollow)" :icon="scope.row.isFollow==0?'el-icon-plus':'el-icon-check'">{{isFollowText(scope.row.isFollow)}}</el-button>
            <el-button type="info" size="mini" @click="gotoInfo(scope.row.id)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: "Search",
  data(){
    return{
      form: {
        pageNum: 1,
        pageSize: 10,
        value: '',
        userId: JSON.parse(localStorage.getItem("user")).id
      },
      gridData: [],
      currentuserId: JSON.parse(localStorage.getItem("user")).id,
      IDS: []
    }
  },
  created(){
    this.loadData()
  },
  watch:{
    "form.value":{
      handler(newval,oldval){
        this.loadData()
      }
    }
  },
  methods:{
    loadData(){
      let iDS = [];
      request.get("/user/search",{
        params: this.form
      }).then(res=>{
        this.gridData = res.data
      }).catch(err=>{
        console.log(err)
      })
    },
    isFollowText(isFollow){
      if(isFollow != 1){
        return "关注"
      }else{
        return "已关注"
      }
    },
    followBlogUser(userId,isFollow){

      request.get(`/user/follow/update/${isFollow}/${userId}/${this.currentuserId}`).then(res=>{
        if(res.code=='200'){
          this.$notify({
            title: '操作成功',
            type: 'success'
          })
          this.loadData()
        }
      }).catch(err=>{
        console.log(err)
      })
      console.log(userId)
    },
    gotoInfo(id){
      this.$router.push({
        path: '/userfollow',
        query: {
          id
        }
      })
    }
  }
}
</script>

<style>

</style>
