import axios from "axios";
const request = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 5000
})
//请求前干的事情
request.interceptors.request.use(config=>{
    config.headers['Content-Type'] = 'application/json;charset=utf-8';

    //config.headers['token'] = user.token //设置请求头
    return config;
},error => {
    // this.$message.error(error)
    return Promise.reject(error)
})

//请求后干的事情
request.interceptors.response.use(response=>{
    let res = response.data
    if(typeof res === 'string'){
        res = res?JSON.parse(res):res
    }
    return res;
},error => {
    console.log("err:"+error)
    // this.$message.error(error)
    return Promise.reject(error)
})

export default request
