<template>
  <div>
    <div style="font-size: 24px;font-weight: bold;margin-left: 30px">关注列表</div>

    <div style="display: flex">
      <div style="flex: 1">
        <el-card class="box-card">
          <div v-for="item in dataList" :key="item.id" class="text item">
            <el-avatar :src="item.avatar" size="large" ></el-avatar>
            <span class="span" @click="info(item)">{{item.name}}</span>
            <div style="float: right;display: inline-block">
              <el-tag type="success" v-if="item.isFollow==1">互关</el-tag>
              <el-button @click="cancelFollow(item)" type="primary" icon="el-icon-minus">
                取关</el-button>
              <el-button  icon="el-icon-s-comment" type="success" @click="sixin(item)">私信</el-button>
            </div>
            <el-divider></el-divider>
          </div>
        </el-card>
      </div>
      <div style="flex: 3">
        你好</div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: 'Follows',
  data(){
    return{
      dataList: []
    }
  },
  created() {
    this.loadData()
  },
  methods:{
    sixin(item) {
      console.log(item)
      let cus = {
        customerId: item.id,
        name: item.name
      }
      localStorage.setItem("customer",JSON.stringify(cus))
      this.$router.push('/chat')
    },
    loadData(){
      request.get(`/user/followsList/${JSON.parse(localStorage.getItem("user")).id}`).then(res=>{
        if(res.code=='200'){
          console.log(res.data)
          this.dataList = res.data
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    //取关
    cancelFollow(item){
      console.log(item)
      request.get(`/user/follow/update/1/${item.id}/${JSON.parse(localStorage.getItem("user")).id}`)
          .then(res=>{
            if(res.code=='200'){
              this.$message.success("操作成功")
              this.loadData()
            }
          }).catch(err=>{
            console.log(err)
      })
    },
    info(item){
      console.log(item)
    }
  }
}
</script>

<style>
.text {
  font-size: 18px;
}

.item {
  padding: 18px 0;
}

.box-card {
  width: 400px;
}
.span:hover{
  cursor: pointer;
  color: #e3bebe;
}
</style>
