<template>
  <div>
    <div style="text-align: center;font-size: 30px;margin-bottom: 20px;margin-top: 10px;font-weight: bold">
      个人中心</div>
    <el-descriptions class="margin-top" title="带边框列表" :column="3" :size="size" border>
      <template slot="extra">
        <el-button type="primary" @click="openedit" size="small">启动编辑</el-button>
      </template>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user"></i>
          名字
        </template>
        <el-input v-model="userInfo.name" :disabled="disabled"></el-input>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-mobile-phone"></i>
          手机号
        </template>
        <el-input v-model="userInfo.phone" :disabled="disabled"></el-input>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-office-building"></i>
          居住地
        </template>
        <el-input v-model="userInfo.address" :disabled="disabled"></el-input>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-tickets"></i>
          性别
        </template>
        <el-input v-model="userInfo.sex" :disabled="disabled"></el-input>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-lightning"></i>
          年龄
        </template>
        <el-input v-model="userInfo.age" :disabled="disabled"></el-input>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-tickets"></i>
          头像
        </template>
        <el-upload
            class="avatar-uploader"
            :action="$baseUrl +'/user/upload'"
            :show-file-list="false"
            :on-success="handleAvatarSuccess">
          <img v-if="userInfo.avatar" :src="userInfo.avatar" style="height: 250px;width: 250px">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-descriptions-item>
    </el-descriptions>

    <el-button v-if="editButton" @click="edit">提交</el-button>

    <div style="margin-top: 100px">
      <el-divider content-position="left">其他详情</el-divider>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: 'UserInfo',
  data(){
    return{
      userInfo:{
      },
      disabled: true,
      editButton: false
    }
  },
  created() {
    this.initData()
  },
  methods:{
    initData(){
      // this.userInfo = JSON.parse(localStorage.getItem("user"))
      request.get(`/user/info/${JSON.parse(localStorage.getItem("user")).id}`).then(res=>{
        if(res.code=='200'){
          this.userInfo = res.data
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    handleAvatarSuccess(res) {
      this.userInfo.avatar = res.data
      console.log(res)
    },
    edit(){
      console.log(this.userInfo)
      request.post("/user/update",this.userInfo).then(res=>{
        if(res.code == '200'){
          this.$message.success("修改成功")
          this.disabled = true
          this.editButton = false
          localStorage.setItem("user",JSON.stringify(res.data))
          this.initData()
        }else {
          console.log(res.msg)
          this.$message.error(res.msg)
        }
      }).catch(err=>{
        console.log("err=>",err)
        this.editButton = false
      })
    },
    openedit(){
      if(this.disabled == true){
        this.disabled = false
        this.editButton = true
      }else{
        this.disabled = true
        this.editButton = false
      }

    }
  }
}
</script>

<style>

</style>


