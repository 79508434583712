<template>
  <div>
    <div style="margin-bottom: 5px">
      <el-input style="display: inline-block;width: 20%;margin-left: 10px" placeholder="请输入分类名" clearable v-model="params.name"></el-input>
      <el-button type="primary" style="margin-left: 5px" @click="add">新增</el-button>
    </div>

    <div>
      <el-table :data="tableData" height="450" style="width: 30%">
        <el-table-column prop="name" label="分类名称"></el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <el-button size="mini" type="success" @click="edit(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="del(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
          small
          layout="total,prev,pager,next,sizes"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :total="total">
      </el-pagination>
    </div>


    <el-dialog
        :title="formTitle"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="reset"
        >
      <el-form label-position="right" label-width="80px" :model="form">
        <el-form-item label="名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="reset">取 消</el-button>
    <el-button type="primary" @click="save">确 定</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: "Category",
  data(){
    return{
      tableData: [],
      form: {},
      dialogVisible: false,
      formTitle: '',
      params: {
        pageNum: 1,
        pageSize: 10,
        name: ''
      },
      total: ''
    }
  },
  created(){
    this.loadData()
  },
  watch:{
    "params.name":{
      handler(newval,oldval){
        this.params.pageNum = 1
        this.params.name = newval
        this.loadData()
      }
    }
  },
  methods:{
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.params.pageSize=val
      this.loadData()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.params.pageNum=val
      this.loadData()
    },
    loadData(){
      request.get("/category/getbyname",{
        params: this.params
      }).then(res=>{
        if(res.code=='200'){
          this.tableData = res.data.list
          this.total = res.data.total
        }else{
          console.log(res.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    reset(){
      this.form = {}
      this.dialogVisible = false
    },
    edit(row){
      request.get(`/category/getbyid/${row.id}`).then(res=>{
        if(res.code=='200'){
          this.form = res.data
        }else{
          this.$message.error(res.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
      this.formTitle = "编辑"
      this.dialogVisible = true
    },
    add(){
      this.formTitle = "新增"
      this.dialogVisible = true
    },
    save(){
      if(this.form.id){
        //编辑
        request.post("/category/updateById",this.form).then(res=>{
          if(res.code=='200'){
            this.$message.success("更新成功")
            this.loadData()
          }else{
            this.$message.error(res.msg)
          }
        }).catch(err=>{
          console.log(err)
        })
      }else{
        //新增
        request.post("/category/add",this.form).then(res=>{
          if(res.code=='200'){
            this.$message.success("新增成功")
            this.loadData()
          }else{
            this.$message.error(res.msg)
          }
        }).catch(err=>{
          console.log(err)
        })
      }
      this.reset()
    }
  }
}
</script>

<style>

</style>
