<template>
  <div>

    <div style="width: 100%;height: 50px;margin-bottom: 10px">
      <el-input style="display: inline-block;width: 20%;margin-right: 10px" v-model="params.name" placeholder="请输入姓名"></el-input>
      <el-input style="display: inline-block;width: 20%;margin-right: 10px" v-model="params.phone" placeholder="请输入联系方式"></el-input>
      <el-button @click="clear" >清空</el-button>
      <el-button type="primary" @click="loadData">查询</el-button>
    </div>
    <el-table
        height="400"
        :data="tableData"
        border
        style="width: 100%">
      <el-table-column
          fixed
          prop="name"
          label="姓名"
          width="100">
      </el-table-column>
      <el-table-column
          prop="username"
          label="账号"
          width="100">
      </el-table-column>
      <el-table-column
          prop="age"
          label="年龄"
          width="150">
      </el-table-column>
      <el-table-column
          prop="sex"
          label="性别"
          width="100">
      </el-table-column>
      <el-table-column
          prop="phone"
          label="联系方式"
          width="150">
      </el-table-column>
      <el-table-column
          prop="password"
          label="密码"
          width="150">
      </el-table-column>
      <el-table-column label="权限" width="150">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.role=='ROLE_ADMIN'">{{scope.row.role}}</el-tag>
          <el-tag type="info" v-if="scope.row.role=='ROLE_COMMON'">{{scope.row.role}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
          prop="avatar"
          label="头像"
          width="150"
      >
        <template slot-scope="scope">
          <img :src="scope.row.avatar" min-width="70" height="70"
          @click="previewPic(scope.row.avatar)"/>
        </template>
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          >
        <template slot-scope="scope">
          <el-button @click="handleEdit(scope.row)" type="success" size="small">编辑</el-button>
          <el-button @click="delete1(scope.row)"  type="danger" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        :page-size="this.params.pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="this.params.pageNum"
        :page-sizes="[2, 5, 10, 20, 100]"
        layout="total,prev,pager,next,sizes,jumper"
        :total="total">
    </el-pagination>

    <!-- 图片预览   放在表格外面，这是个弹出狂-->
    <el-dialog :visible.sync="dialogVisible" :modal="false" title="图片预览" width="50%">
      <img :src="previewpic" alt=""  width="100%" />
      <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false">OK</el-button>
          </span>
    </el-dialog>



<!--用户编辑-->
    <el-dialog title="编辑用户" :visible.sync="dialogFormVisible">
      <div>
          <el-form label-position="right" label-width="80px" :inline="true" :model="form"  class="demo-form-inline">
            <el-form-item label="姓名">
              <el-input v-model="form.name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="账号">
              <el-input v-model="form.username" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="年龄">
              <el-input v-model="form.age" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="性别">
              <el-input v-model="form.sex" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="联系方式">
              <el-input v-model="form.phone" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="权限角色">
              <el-select v-model="form.role" placeholder="请选择">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="密码">
              <el-input v-model="form.password" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="头像">
              <el-upload
                  class="avatar-uploader"
                  :action="$baseUrl+'/user/upload'"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess">
                <img v-if="form.avatar" :src="form.avatar" style="height: 250px;width: 250px">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-form>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="editUser">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: 'User',
  data(){
    return{
      dialogVisible: false,
      dialogFormVisible: false,
      previewpic: "",
      tableData: [],
      options:[
        {
          value: "ROLE_ADMIN",
          label: "ROLE_ADMIN"
        },
        {
          value: "ROLE_COMMON",
          label: "ROLE_COMMON"
        }
      ],
      params: {
        pageSize: 5,
        pageNum: 1,
        name: '',
        phone: ''
      },
      total: 5,
      form: {
        sex: ''
      }
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    editUser(){
      request.post("/user/update",this.form).then(res=>{
        if(res.code=='200'){
          this.$message.success("编辑成功")
        }else{
          this.$message.error(res.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    clear(){
      this.params.phone = ''
      this.params.name = ''
    },
    loadData(){
      request.get("/user/page",{
        params: this.params
      }).then(res=>{
        this.tableData = res.data.list
        this.total = res.data.total
      }).catch(err=>{
        console.log(err)
      })
    },
    handleAvatarSuccess(res) {
      this.form.avatar = res.data
    },
    //预览大图
    previewPic(url) {
      this.previewpic = url;
      this.dialogVisible = true;
    },
    handleEdit(row){
      this.form = row
      this.dialogFormVisible = true

    },
    delete1(row){
      this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        request.delete(`/user/${row.id}`).then(res=>{
          if(res.code=='200'){
            this.$message.success("删除成功")
            this.loadData()
          }
        }).catch(err=>{
          console.log(err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    handleSizeChange(val){
      this.params.pageSize = val
      this.loadData()
    },
    handleCurrentChange(val){
      this.params.pageNum = val
      this.loadData()
    }
  }
}
</script>

<style>
.abow_dialog {
  display: flex;
  justify-content: center;
  align-items: Center;
  overflow: hidden;
  .el-dialog {
    margin: 0 auto !important;
    height: 90%;
    overflow: hidden;
    .el-dialog__body {
      position: absolute;
      left: 0;
      top: 54px;
      bottom: 0;
      right: 0;
      padding: 0;
      z-index: 1;
      overflow: hidden;
      overflow-y: auto;
    }
  }
}
</style>
